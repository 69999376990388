<template>
  <table-overview
    title="Websites stats timetable"
    icon="far fa-fw fa-globe"
    store="websites-stats-timetable"
    :headers="headers"
    force-initial-sort="total"
    descending
    sticky-thead
    sticky-first-column
    striped-columns
    searchable
    class="websites-stats-timetable"
  >
    <v-select
      slot="toolbar-additional-search"
      v-model="dataType"
      :items="dataTypes"
      label="Data type"
      solo-inverted
      flat
      class="pl-3"
      style="z-index: 13; max-width: 300px;"
      hide-details
    />
    <websites-timetable-table-row
      slot="table-row"
      slot-scope="{ item }"
      :website-id="item"
    />
    <websites-timetable-filters
      slot="filters"
      :dataTypes="dataTypes"
      store="websites-stats-timetable"
    />
  </table-overview>
</template>
<script type="text/babel">
import TableOverview from "../../../components/generic/overview/TableOverview";
import Moment from "moment";
import websitesStatsTimetableModule from "@/store/modules/websites-stats-timetable";
import { mapOverviewLifecycleMethods } from "@/views/helpers";
import { createNamespacedHelpers as createNamespacedHelpersFromVuex } from "vuex";
import { createNamespacedHelpers } from "@/store/helpers";
import WebsitesTimetableTableRow from "@/components/statistics/websites/WebsitesTimetableTableRow";
import WebsitesTimetableFilters from "@/components/statistics/websites/WebsitesTimetableTableFilter";
import { FETCH } from "@/store/templates/table/action-types";

const { mapGetters } = createNamespacedHelpersFromVuex(
  "websites-stats-timetable"
);
const { mapComputedFilters } = createNamespacedHelpers(
  "websites-stats-timetable"
);

const overviewLifecycleMethods = mapOverviewLifecycleMethods(
  "websites-stats-timetable",
  websitesStatsTimetableModule
);

export default {
  name: "websites-stats-timetable-view",
  components: {
    WebsitesTimetableFilters,
    WebsitesTimetableTableRow,
    TableOverview
  },
  data: () => ({
    dataTypes: [
      {
        text: "Conversions",
        value: "conversions"
      },
      {
        text: "Visitors",
        value: "visitors"
      }
    ]
  }),
  computed: {
    ...mapComputedFilters(["from", "to", "by", "dataType"]),
    ...mapGetters(["dataCountsHeaders"]),
    headers() {
      return [
        { text: "Website", value: "host" },
        {
          text: this.totalHeader,
          value: "total",
          align: "right"
        },
        ...this.dateHeaders
      ];
    },
    totalHeader() {
      if (!this.dateHeaders.length) {
        return "Total (loading...)";
      }
      return `Total (${this.dateHeaders[this.dateHeaders.length - 1].text} - ${
        this.dateHeaders[0].text
      })`;
    },
    dateHeaders() {
      return this.dataCountsHeaders.map(date => {
        let dateFormat = "MMM YYYY";
        if (date.length === 4) {
          dateFormat = "YYYY";
          date += "-01";
        }
        return {
          text: Moment(date).format(dateFormat),
          value: date,
          sortable: true,
          align: "right"
        };
      });
    }
  },
  watch: {
    async dataType(type) {
      if (type === "visitors") {
        this.by = "month";
      }
      await this.$store.dispatch(`websites-stats-timetable/${FETCH}`);
    }
  },
  ...overviewLifecycleMethods
};
</script>
<style lang="scss">
.websites-stats-timetable {
  th {
    color: white !important;
    background-color: #2e6fb4 !important;
  }
  td:nth-child(1n + 2) {
    text-align: right;
  }
}
</style>
